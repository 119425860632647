<template>
  <div id="authentication">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <b-row>
        <b-col id="action-area" md="6" offset-md="4">
          <h1 class="text-center">Reset Password</h1>
          <div v-bind:class="{ cognitoError: cognitoResponse }" class="text-center">
            {{ cognitoResponse }}
          </div>
          <p class="text-center">{{ successMessage }}</p>
          <b-form v-on:submit.prevent="confirmPassword">
            <b-form-group id="passwordGroup">
              <b-form-input
                v-bind:disabled="busy"
                size="lg"
                v-model="password"
                required
                class="Control__input"
                name="password"
                type="password"
                autocomplete="current-password"
                placeholder="Password"
              />
            </b-form-group>
            <b-row>
              <b-button
                v-bind:disabled="busy"
                class="authButton"
                size="lg"
                block
                type="submit"
                variant="primary"
              >
                <div v-if="busy">
                  <Busy light :margin="0" />
                </div>
                <div v-else>Set Password</div>
              </b-button>
            </b-row>
          </b-form>
          <p class="text-center">
            Already got an account?
            <router-link :to="{ name: 'signin' }">Sign in</router-link>
          </p>
          <p class="text-center">
            New to Reducer?
            <router-link :to="{ name: 'signup' }">Create an account</router-link>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/authentication.scss";
</style>

<script>
import CognitoAuth from "@/cognito/cognitoauth";
import PasswordHelper from "@/helper/passwordhelper";
import router from "@/router";
import Busy from "@/components/Busy";

export default {
  name: "confirm-reset-password",
  components: {
    Busy
  },
  mounted: function () {
    document.body.className = "authenticationBody";
    if (!this.code) {
      this.busy = false;
      this.cognitoResponse = "No verification code was supplied, we cannot reset your password";
    } else if (!this.username) {
      this.cognitoResponse = "No email address was supplied, we cannot reset your password";
    } else this.resetPassword();
  },
  destroyed: function () {
    document.body.className = "";
  },
  data() {
    return {
      title: "Password Reset",
      successMessage: "Enter your new password",
      cognitoResponse: null,
      code: this.$route.query.code,
      username: this.$route.query.username,
      password: null,
      busy: false
    };
  },
  methods: {
    cognitoFail(err) {
      this.busy = false;
      switch (err.code) {
        case "UserNotFoundException":
          this.cognitoResponse =
            "Sorry, we could not find a user with that email address, please sign up";
          break;
        default:
          this.cognitoResponse = err.message;
      }
    },
    cognitoSuccess() {
      this.busy = false;
      this.successMessage = "Your password has successfully been updated.";
      router.push({
        name: "signin",
        params: { message: "Your password was successfully updated.", emailprop: this.username }
      });
    },
    confirmPassword() {
      this.busy = true;
      this.cognitoResponse = null;
      if (PasswordHelper.passwordContainsDictionaryWord(this.password)) {
        this.busy = false;
        this.cognitoResponse = "Password cannot be a dictionary word";
      } else {
        CognitoAuth.confirmPassword(
          this.username,
          this.password,
          this.code,
          this.cognitoSuccess,
          this.cognitoFail
        );
      }
    }
  }
};
</script>